import React from "react";

const CardOurProcess = ({ name, description, duration, img }) => {
  return (
    <div className="card card-compact w-64 h-72 bg-[#1b1b1b] shadow-xl">
      <figure>
        <img
          src={img}
          alt="images"
          loading="lazy"
          className="object-cover h-60"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title">{name}</h2>
        <p className="">{description}</p>
      </div>
    </div>
  );
};

export default CardOurProcess;
