import React, { useState } from "react";
import ModalContactForm from "../forms/ModalContactForm";
import { Menu, X } from "@geist-ui/icons";
import { HashLink as Link } from "react-router-hash-link";
import Logo from "../../assets/logo/logo.png";

const Navbar = () => {
  const [menu, setOpenMenu] = useState(false);

  const logo = "PageSolo";
  const link1 = "Portfolio";

  const handleMenu = () => {
    setOpenMenu(!menu);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="navbar absolute top-0">
      <div className="flex-1">
        <Link to="/" className="btn btn-ghost text-xl">
          <img src={Logo} alt="" className="h-5" />
          {logo}
        </Link>
      </div>
      {/* Desktop Nav */}
      <div className="hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <Link className="font-medium" smooth to="/#portfolio">
              {link1}
            </Link>
          </li>
          <li>
            <Link to="/#process" className="font-medium">
              {" "}
              Process
            </Link>
          </li>
          <li>
            <Link className="font-medium" to="/#pricing">
              Pricing
            </Link>
          </li>
          <li>
            <Link className="font-medium" onClick={handleMenu} to="/contact">
              Contact
            </Link>
            {}
          </li>
        </ul>
      </div>

      {/* Mobile Nav */}
      <div
        onClick={handleMenu}
        className="w-fit h-fit fixed z-[100] block lg:hidden top-5 right-5"
      >
        {menu ? <X /> : <Menu />}
      </div>
      <div
        className={`fixed h-full w-full bg-base-300 top-0 justify-center duration-200 items-center lg:hidden flex z-50 ${
          menu ? "left-0" : "left-[100vw]"
        }`}
      >
        <Link to="#home" onClick={handleMenu}>
          <div className="w-fit h-fit fixed top-32 flex justify-center items-center gap-3">
            <img src={Logo} alt="" className="h-7" />
            <h1 className=" font-bold text-3xl">{logo}</h1>
          </div>
        </Link>
        <div className="">
          <ul className="menu menu-vertical gap-5 px-1">
            <li>
              <Link
                to="/"
                className="text-xl"
                onClick={() => {
                  handleMenu();
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link to="/#portfolio" className="text-xl" onClick={handleMenu}>
                {link1}
              </Link>
            </li>
            <li>
              <Link to="/#process" className="text-xl" onClick={handleMenu}>
                Process
              </Link>
            </li>
            <li>
              <Link to="/#pricing" className="text-xl" onClick={handleMenu}>
                Pricing
              </Link>
            </li>

            <li>
              <Link
                className="text-xl"
                onClick={() => {
                  handleMenu();
                  scrollToTop();
                }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
