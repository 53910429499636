import React, { useState } from "react";
import ContactUs from "../components/forms/ContactUs";
import {
  StarterOptionContext,
  PremiumOptionContext,
} from "../contexts/pricingContexts";
// Remove the duplicate import statement for StarterOptionContext and PremiumOptionContext

const ContactPage = () => {
  const [starterOption, setStarterOption] = useState(null);
  const [premiumOption, setPremiumOption] = useState(null);

  return (
    <div className="min-h-screen w-full py-24">
      <StarterOptionContext.Provider value={[starterOption, setStarterOption]}>
        <PremiumOptionContext.Provider
          value={[premiumOption, setPremiumOption]}
        >
          <ContactUs />
        </PremiumOptionContext.Provider>
      </StarterOptionContext.Provider>
    </div>
  );
};

export default ContactPage;
