import React, { useEffect, useRef, useState } from "react";
import Create from "../../assets/images/sites/create.png";
import Form from "../../assets/images/sites/form.png";
import Hero from "../../assets/images/sites/heromob.png";
import Process from "../../assets/images/sites/localhost_3000_(iPhone 12 Pro).png";
import Image1 from "../../assets/images/sites/Screenshot 2024-05-11 at 10.01.55 PM.png";
import Image2 from "../../assets/images/sites/Screenshot 2024-05-11 at 8.48.26 PM.png";
import Bento from "../../assets/images/sites/Screenshot 2024-05-12 at 10.39.38 PM.png";
import Fitness from "../../assets/images/sites/Screenshot 2024-05-12 at 10.50.47 PM.png";

import { ReactTyped } from "react-typed";
import { useScroll, useTransform, motion, useSpring } from "framer-motion";

const Portfolio = () => {
  const container1 = useRef();
  const { scrollYProgress: scrollYProgress1 } = useScroll({
    target: container1,
    offset: ["start end", "center end"],
  });
  const container2 = useRef();
  const { scrollYProgress: scrollYProgress2 } = useScroll({
    target: container2,
    offset: ["start end", "end end"],
  });
  const container3 = useRef();
  const { scrollYProgress: scrollYProgress3 } = useScroll({
    target: container3,
    offset: ["start end", "end end"],
  });

  // Spring
  const springConfig = { damping: 10, stiffness: 100, mass: 0.5 };

  // For the layers to slightly grow as they trans in
  const growLayer1 = useTransform(scrollYProgress1, [0, 0.6], [0, 1]);
  const growLayer2 = useTransform(scrollYProgress2, [0, 0.6], [0, 1]);
  const growLayer3 = useTransform(scrollYProgress3, [0, 0.6], [0, 1]);

  // The far left images through to the far right images to create a async effect
  const layer1 = useSpring(
    useTransform(scrollYProgress1, [0, 0.6], [1000, 0]),
    springConfig
  );
  const layer1Col2 = useSpring(
    useTransform(scrollYProgress1, [0, 0.85], [1000, 0]),
    springConfig
  );
  const layer1Col3 = useSpring(
    useTransform(scrollYProgress1, [0, 1], [1000, 0]),
    springConfig
  );

  const layer2 = useSpring(
    useTransform(scrollYProgress2, [0, 0.6], [1000, 0]),
    springConfig
  );
  const layer2Col2 = useSpring(
    useTransform(scrollYProgress2, [0, 0.8], [1000, 0]),
    springConfig
  );
  const layer2Col3 = useSpring(
    useTransform(scrollYProgress2, [0, 1], [1000, 0]),
    springConfig
  );

  const layer3 = useSpring(
    useTransform(scrollYProgress3, [0, 0.6], [1000, 0]),
    springConfig
  );
  const layer3Col2 = useSpring(
    useTransform(scrollYProgress3, [0, 0.8], [1000, 0]),
    springConfig
  );
  // const layer3Col3 = useSpring(
  //   useTransform(scrollYProgress3, [0, 1], [1000, 0]),
  //   springConfig
  // );

  return (
    <div
      id="portfolio"
      className="min-h-screen w-full bg-base-200 flex lg:flex-row flex-col justify-evenly items-center overflow-x-hidden"
    >
      <h1 className="lg:text-5xl text-2xl font-bold">Portfolio</h1>
      <div
        ref={container1}
        className="grid lg:h-[700px] h-[400px] max-w-[690px] grid-cols-4 grid-rows-4 gap-4 p-5"
      >
        <motion.div
          style={{ x: layer1, scale: growLayer1 }}
          className="rounded-xl flex justify-center items-center col-span- row-span-2 overflow-hidden"
        >
          <img src={Hero} className="object-cover w-full h-full" alt="" />
        </motion.div>
        <motion.div
          style={{ x: layer1Col2, scale: growLayer1 }}
          className="rounded-xl flex justify-center items-center col-span- row-span- overflow-hidden"
        >
          <img src={Bento} alt="" className="object-cover w-full h-full" />
        </motion.div>

        <motion.div
          style={{ x: layer1Col3, scale: growLayer1 }}
          className="rounded-xl flex justify-center items-center col-span-2 row-span-1 overflow-hidden"
        >
          <img src={Create} className="object-cover w-full h-full" alt="" />
        </motion.div>

        <div className="rounded-xl flex flex-col justify-center bg-base-100 items-center col-span-2 row-span-">
          {/*  React Typed */}
          <h1 className="lg:text-2xl text-sm font-semibold">Your 24/7 </h1>
          <h1 className="lg:text-2xl text-sm font-semibold">
            <ReactTyped
              strings={["Salesperson ", "Showcase ", "Advantage.  "]}
              typeSpeed={70}
              backSpeed={60}
              loop
              className="text-primary"
            />
          </h1>
        </div>

        <motion.div
          style={{ x: layer2Col3, scale: growLayer2 }}
          className="rounded-xl flex justify-center items-center col-span- row-span-2 overflow-hidden"
        >
          <img src={Form} alt="" className="object-cover w-full h-full" />
        </motion.div>

        <motion.div
          ref={container2}
          style={{ x: layer2, scale: growLayer2 }}
          className="rounded-xl flex justify-center items-center col-span-2 row-span- overflow-hidden"
        >
          <img src={Image2} className="w-full h-full object-cover" alt="" />
        </motion.div>
        <motion.div
          style={{ x: layer2Col2, scale: growLayer2 }}
          className="rounded-xl flex justify-center items-center col-span- row-span-2"
        >
          <img src={Process} className="object-cover w-full h-full" alt="" />
        </motion.div>
        {/* <div className="rounded-xl flex justify-center items-center col-span- row-span-"></div> */}

        <motion.div
          ref={container3}
          style={{ x: layer3, scale: growLayer3 }}
          className="rounded-xl flex justify-center items-center col-span-2 overflow-hidden row-span-"
        >
          <img src={Fitness} className="w-full h-full object-cover" alt="" />
        </motion.div>
        <motion.div
          style={{ x: layer3Col2, scale: growLayer3 }}
          className="rounded-xl flex justify-center items-center col-span- row-span- overflow-hidden"
        >
          <img src={Image1} className="w-full h-full object-cover" alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default Portfolio;
