import React, { useRef } from "react";
import CircleDrawing1 from "../../assets/images/circles/full-m2i8Z5b1A0m2N4b1.png";
import CircleDrawing2 from "../../assets/images/circles/PikPng.com_drawn-circle-png_1054599.png";
import { useScroll, useTransform, motion } from "framer-motion";

const YourNeeds = () => {
  const container = useRef();

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"],
  });

  //   const slide1 = useTransform(scrollYProgress, [0, 1], [1000, 0]);
  const scale1 = useTransform(scrollYProgress, [1, 0], [1, 0]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  return (
    <div
      ref={container}
      className="min-h-screen w-full bg-base-200 flex justify-center items-center"
    >
      <div className="grid lg:h-[700px] h-[400px] w-[690px] grid-cols-3 grid-rows-4 gap-4 p-5">
        <div className="rounded-xl flex justify-center items-center col-span-1 row-span-1 overflow-hidden">
          <div className="w-fit h-fit rotate-[-8deg]">
            <motion.img
              style={{ opacity: opacity, scale: scale1 }}
              src={CircleDrawing1}
              alt=""
              className="w-40 relative"
            />
            <h1 className="font-medium absolute top-[50%] translate-y-[-50%] text-lg w-full text-center">
              Contact Form
            </h1>
          </div>
        </div>
        <div className="rounded-xl flex justify-center items-center col-span-1 row-span-1 overflow-hidden">
          <h1 className="font-medium text-lg  rotate-[2deg]">Animations</h1>
        </div>
        <div className="rounded-xl flex justify-center items-center col-span-1 row-span-1 overflow-hidden">
          <h1 className="font-medium text-lg  rotate-[-2deg]">Gallery</h1>
        </div>
        <div className="w-full h-full col-span-3 row-span-2 rounded-xl flex justify-center items-center grid-rows-">
          <div className="w-fit h-fit flex justify-center items-center flex-col gap-3">
            <div className="w-fit h-fit flex justify-center items-center gap-2 border-b-[1px] border-[#333333] pb-2">
              <h1 className="lg:text-5xl text-2xl font-bold">Select what</h1>
              <motion.h1
                style={{ opacity: opacity, scale: scale1 }}
                className="lg:text-6xl text-3xl font-bold text-primary"
              >
                YOU
              </motion.h1>
              <h1 className="lg:text-5xl text-2xl font-bold">need</h1>
            </div>
            <div className="w-fit  text-md flex flex-col items-center justify-center gap-[2px]">
              <h1 className="">At PageSolo, we keep it simple.</h1>
              <h1 className="w-auto text-center">
                No unneccessary features, no unneccessary cost.
              </h1>
            </div>
          </div>
        </div>

        <div className="rounded-xl flex justify-center items-center col-span-1 row-span-1 overflow-hidden">
          {" "}
          <div className="w-fit h-fit  rotate-[5deg]">
            <motion.img
              style={{ opacity: opacity, scale: scale1 }}
              src={CircleDrawing2}
              alt=""
              className="w-40 relative"
            />
            <h1 className="font-medium absolute top-[55%] translate-y-[-50%] left-3 text-lg w-full text-center ">
              About Us
            </h1>
          </div>
        </div>
        <div className="rounded-xl flex justify-center items-center col-span-1 row-span-1 overflow-hidden">
          <h1 className="font-medium text-lg rotate-[-3deg]">Videos</h1>
        </div>
        <div className="rounded-xl flex justify-center items-center col-span-1 row-span-1 overflow-hidden">
          {" "}
          <div className="w-fit h-fit rotate-[7deg]">
            <motion.img
              style={{ opacity: opacity, scale: scale1 }}
              src={CircleDrawing1}
              className="w-40 rotate-180 relative"
              alt=""
            />
            <h1 className="font-medium absolute top-[50%] translate-y-[-50%] text-lg w-full text-center">
              Dark Mode
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourNeeds;
