import React, { useEffect, useRef, useState } from "react";
import CardOurProcess from "./CardOurProcess";
import Img1 from "../../assets/images/process/maranda-vandergriff-7aakZdIl4vg-unsplash.jpg";
import Img2 from "../../assets/images/process/visual-design-pl9DrjAMV6I-unsplash.jpg";
import Img3 from "../../assets/images/process/emile-perron-xrVDYZRGdw4-unsplash.jpg";
import Img4 from "../../assets/images/process/le-buzz-studio-9H0ZA1oPsYE-unsplash.jpg";

import { motion, useTransform, useScroll, useSpring } from "framer-motion";

const OurProcess = () => {
  const container = useRef();

  // For scrolling up
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["", ""],
  });

  const springConfig = { damping: 10, stiffness: 100, mass: 0.5 };

  const left1 = useSpring(
    useTransform(scrollYProgress, [0, 1], [-500, 0]),
    springConfig
  );
  const left2 = useSpring(
    useTransform(scrollYProgress, [0, 0.7], [-500, 0]),
    springConfig
  );
  const right1 = useSpring(
    useTransform(scrollYProgress, [0, 0.7], [500, 0]),
    springConfig
  );
  const right2 = useSpring(
    useTransform(scrollYProgress, [0, 1], [500, 0]),
    springConfig
  );

  return (
    <div
      id="process"
      className="min-h-screen w-full overflow-hidden flex flex-col gap-10 justify-center items-center py-10 "
    >
      <h1 className="font-bold lg:text-5xl text-2xl">Our Process</h1>
      <p className="w-[75%] text-center">
        PageSolo ensures a top-quality website with a four-step process:
        connecting, designing, building, and deploying
      </p>
      <div
        ref={container}
        className="grid w-fit justify-center items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4 flex-col lg:gap-8 gap-16"
      >
        <motion.div
          // style={{ x: left1 }}
          className="h-fit w-60 lg:w-64 flex justify-center items-center"
        >
          <CardOurProcess
            name="Step 1: Connect"
            description="Let's have a chat about what you're looking for."
            img={Img1}
          />
        </motion.div>
        <motion.div
          // style={{ x: left2 }}
          className="h-fit w-60 lg:w-64 flex justify-center items-center"
        >
          <CardOurProcess
            name="Step 2: Design"
            description="Let's go through some designs and pick out what's right for you."
            img={Img2}
          />
        </motion.div>
        <motion.div
          // style={{ x: right1 }}
          className=" h-fit w-60 lg:w-64 flex justify-center items-center "
        >
          <CardOurProcess
            name="Step 3: Build"
            description="Our developers will transform your visions into a reality."
            img={Img3}
          />
        </motion.div>
        <motion.div
          // style={{ x: right2 }}
          className=" h-fit w-60 lg:w-64 flex justify-center items-center"
        >
          <CardOurProcess
            name="Step 4: Deploy"
            description="We will deploy your website once you have approved."
            img={Img4}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default OurProcess;
