import React from "react";
import Design from "../../assets/images/pros/palette (1).png";
import Hosting from "../../assets/images/pros/database.png";
import Coding from "../../assets/images/pros/rectangle-code (1).png";

const OurBenefits = () => {
  // Use this whilst Pros.jsx is in dev
  return (
    <div className="min-h-screen w-full flex flex-col justify-center gap-24 items-center bg-base-200">
      <div className="w-fit h-fit flex flex-col gap-1 justify-center items-start">
        <h1 className="lg:text-2xl text-md font-medium">We make it simple.</h1>
        <div className="w-fit h-fit flex gap-0 lg:gap-3 lg:flex-row flex-col">
          <h1 className="lg:text-5xl text-2xl font-bold">For a flat fee, </h1>
          <h1 className="lg:text-5xl text-2xl font-bold text-primary">
            we'll create whatever you need.
          </h1>
        </div>
      </div>
      <div className="flex justify-center lg:flex-row flex-col items-center w-fit h-fit gap-10">
        <div className="card card-compact w-64 bg-base-100 shadow-xl hover:scale-110 duration-200">
          <figure>
            <img
              src={Design}
              alt="web design"
              className="h-36 p-5 opacity-50"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Web Design</h2>
            <p>Using Figma to create your ideal design.</p>
            <div className="card-actions justify-end"></div>
          </div>
        </div>
        <div className="card card-compact w-64 bg-base-100 shadow-xl hover:scale-110 duration-200">
          <figure>
            <img
              src={Coding}
              alt="web development"
              className="h-36 opacity-50 p-5"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Web Development</h2>
            <p>Websites developed using React and Tailwind.</p>
            <div className="card-actions justify-end"></div>
          </div>
        </div>
        <div className="card card-compact w-64 bg-base-100 shadow-xl hover:scale-110 duration-200">
          <figure>
            <img
              src={Hosting}
              alt="web updates"
              className="h-36 p-5 opacity-50"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Web Hosting</h2>
            <p>Websites are hosted using Render web services.</p>
            <div className="card-actions justify-end"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBenefits;
