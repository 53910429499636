import React, { useEffect, useRef, useState } from "react";
import Diff from "./Diff";
import { HashLink as Link } from "react-router-hash-link";

const Hero = () => {
  const [windowSize, setWindowSize] = useState(null);

  useEffect(() => {
    const handleWindowChange = () => {
      if (window.innerWidth <= 850) {
        // Adjust this value based on your mobile/tablet breakpoint
        setWindowSize(false);
      } else {
        setWindowSize(true);
      }
    };

    // Call when component mounts and on window resize
    handleWindowChange();
    window.addEventListener("resize", handleWindowChange);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  }, []);

  return (
    <div
      id="home"
      className="hero w-full flex flex-col min-h-screen items-center justify-center bg-base-200"
    >
      <div className="hero-content gap-16 lg:gap-24 flex-col lg:flex-row mt-10">
        <div className="max-w-md relative flex items-center lg:items-start flex-col justify-center">
          <div className="flex lg:justify-start flex-col items-start gap-4">
            <h1 className="lg:text-5xl text-2xl z-10 font-bold mr-2">
              One Page...
            </h1>
            <div className="w-fit h-fit flex justify-center items-center">
              <div className="lg:w-[400px] w-[310px] h-16 absolute z-0 -rotate-1 bg-[#e5e7eb]"></div>

              <h1 className="lg:text-5xl text-4xl z-10 font-bold text-black">
                Maximum Impact
              </h1>
            </div>
          </div>
          <p className="py-6 text-center lg:text-start">
            An <strong>affordable</strong>, <strong>efficient</strong> solution
            to ensure your business stands out and <strong>connects</strong>{" "}
            with your audience.
          </p>
          <Link smooth to="#portfolio" className="w-fit h-fit">
            <button className="btn btn-primary">
              {/* <Camera size={20} /> */}
              Explore Our Work
            </button>
          </Link>
        </div>
        {windowSize ? <Diff /> : ""}
      </div>
    </div>
  );
};

export default Hero;
