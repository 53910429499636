import React from "react";
import Image1 from "../../assets/images/heroimages/MacBook Pro 14_ - 1.png";
import Image2 from "../../assets/images/heroimages/MacBook Pro 14_ - 2.png";

const Diff = () => {
  return (
    <div className="lg:w-[600px] w-[95%]">
      <div className="diff aspect-[16/9] rounded-md">
        <div className="diff-item-1">
          <img alt="daisy" src={Image2} />
        </div>
        <div className="diff-item-2">
          <img alt="daisy" src={Image1} />
        </div>
        <div className="diff-resizer"></div>
      </div>
    </div>
  );
};

export default Diff;
