import { createContext, useEffect, useState } from "react";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ContactPage from "./pages/ContactPage";
import Navbar from "./components/nav/Navbar";
import Footer from "./components/footer/Footer";

export const ThemeContext = createContext();

function App() {
  // #1eb854 is the green colour
  const [theme, setTheme] = useState("forest");

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <BrowserRouter>
        <div
          data-theme={theme}
          className="App min-h-screen w-full  bg-base-200"
        >
          <Navbar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
          <Footer />
        </div>

        <Toaster position="bottom-right" />
      </BrowserRouter>
    </ThemeContext.Provider>
  );
}

export default App;
