import React, { createContext, useState } from "react";
import Hero from "../components/hero/Hero";
import Navbar from "../components/nav/Navbar";
import Footer from "../components/footer/Footer";
import Portfolio from "../components/portfolio/Portfolio";
import OurProcess from "../components/process/OurProcess";
import YourNeeds from "../components/benefits/YourNeeds";
// import ContactUs from "../components/forms/ContactUs";
import Pricing from "../components/pricing/Pricing";
import OurBenefits from "../components/benefits/OurBenefits";

const LandingPage = () => {
  return (
    <div className="">
      <Hero />
      <Portfolio />
      <YourNeeds />
      <OurBenefits />
      <OurProcess />
      <Pricing />
      {/* <ContactUs /> */}
    </div>
  );
};

export default LandingPage;
